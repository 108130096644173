import React from "react";
import {
  RiInstagramLine,
  RiFacebookCircleFill,
  RiPhoneFill,
  RiMailFill,
  RiMapPin2Fill,
  RiTwitterXLine,
} from "react-icons/ri";
import { TbChristmasTree } from "react-icons/tb";
const Footer = () => {
  return (
    <div className="bg-th-red w-screen mx-auto pb-10 lg:pb-20 pt-10 lg:pt-5">
      <div className="w-10/12 xl:w-9/12 border-t border-white mx-auto">
        <div className="flex justify-between items-start lg:items-center flex-col gap-5 lg:gap-0 lg:flex-row pt-10 lg:pt-20">
          <a href="/">
            <img
              src="assets/images/logos/farmhouse_logo_second.png"
              alt="farmhouse_logo"
              className="w-40 lg:w-52 object-contain"
            />
          </a>
          <div className="flex items-start justify-center">
            <p className="text-white text-lg font-primary lowercase text-left">
              opening hours
              <br />
              <span className="font-secondary text-sm block capitalize">
                <span className="font-bold">
                  BRUNCH
                  <br />
                </span>
                10am - 2:30pm, Saturday & Sunday
                <br />
                <span className="font-bold">
                  LUNCH
                  <br />
                </span>
                12pm - 2:30pm, weekdays
                <br />
                <span className="font-bold uppercase">
                  Happy Hour
                  <br />
                </span>
                2:30pm - 5:30pm, Daily
                <br />
                <span className="font-bold uppercase">
                  Dinner
                  <br />
                </span>
                5:00pm - 10:00pm, Daily
              </span>
            </p>
          </div>
          <div className="flex flex-col justify-center items-start text-white font-secondary text-sm gap-1">
            <a
              href="tel: 604-260-2477"
              className="flex items-center justify-start gap-1"
            >
              <RiPhoneFill className="text-base" />
              604.260.2477
            </a>
            <a
              href="mailto:info@farmhouseon10th.com"
              className="flex items-center justify-start gap-1"
            >
              <RiMailFill className="text-base" /> info@farmhouseon10th.com
            </a>
            <a
              href="https://goo.gl/maps/tDaMXyVGrdqNcr4a7"
              target="_blank"
              rel="noreferrer"
              className="text-white font-secondary text-sm flex items-center justify-start gap-1"
            >
              <RiMapPin2Fill className="text-base" /> 352 E 10th Ave, Vancouver,
              BC
            </a>
          </div>
          <div className="flex flex-col gap-3 items-start lg:items-end justify-center">
            <div className="flex gap-2 items-center">
              <a
                href="https://www.facebook.com/farmhouseon10th"
                target="_blank"
                rel="noreferrer"
              >
                <RiFacebookCircleFill className="text-white fill-white text-3xl hover:fill-th-brown" />
              </a>
              <a
                href="https://www.instagram.com/farmhouseon10th"
                target="_blank"
                rel="noreferrer"
              >
                <RiInstagramLine className="text-white fill-white text-3xl hover:fill-th-brown" />
              </a>
            </div>
            <div className="flex flex-col gap-3">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://mailchi.mp/7032d27029b1/oytpotavp5"
                className="font-primary text-center lowercase text-base lg:text-lg cursor-pointer bg-th-brown text-th-white px-3 py-1 rounded-md transition-all hover:bg-white hover:text-th-brown"
              >
                vip sign up
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.viaggiohospitality.com/giftcards"
                className="font-primary text-center lowercase text-base lg:text-lg cursor-pointer bg-th-brown text-th-white px-3 py-1 rounded-md transition-all hover:bg-white hover:text-th-brown"
              >
                buy gift card
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
